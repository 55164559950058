import { Box, Stack, Typography } from '@mui/material';
import 'react-international-phone/style.css';
import { isFunction, isString, sum } from 'lodash';
import HTMLReactParser from 'html-react-parser';
import { COLOR } from '../../helpers/styles';
import { useState } from 'react';
import { calculateAdjustment, money } from '../../helpers/utils';
import Coupon from '../Coupon/Coupon';
import { language, t } from '../../translations';
import { AdjustmentType } from '../../types';

const getFeeValue = (fee, price) => {
  return calculateAdjustment({
    value: price,
    adjustmentType: fee.chargeType,
    adjustmentValue: fee.amount
  })
};

const PaymentReservationDetails = (props) => {
  const { paxes, cardInfo, order, expenseFees, couponEditable, clientName, agentName, paymentDetailsRef, sx } = props;

  const [coupon, setCoupon] = useState<any>(null);

  const currency = order?.currency || paxes[0].pkg.currency;
  const price = order?.priceWithMarkup || sum(paxes.flatMap(({ pkg }) => Number(pkg.finalPrice)));

  let priceWithCoupon;
  if (order?.priceWithCoupon) {
    priceWithCoupon = order?.priceWithCoupon;
  } else if (coupon?.applicable) {
    const adjustment = calculateAdjustment({
      value: price,
      adjustmentType: coupon.discountType,
      adjustmentValue: coupon.discountAmount
    })
    priceWithCoupon = price - adjustment;
    priceWithCoupon = Number(Number(Math.max(priceWithCoupon, 0)).toFixed(2));
  }
  const couponDiscount = priceWithCoupon ? Number(Number(price - priceWithCoupon).toFixed(2)) : undefined;

  const subtotal = order?.subtotal || priceWithCoupon || price;
  const orderExpenses =
    order?.orderExpenses ||
    expenseFees.map((fee) => ({
      amount: fee.amount,
      chargeType: fee.chargeType,
      text: fee?.textPerLanguage?.[language] || fee.text,
      feePrice: getFeeValue(fee, subtotal),
    }));
  const total = order?.total || Number((subtotal + sum(orderExpenses.map((item) => item.feePrice))).toFixed(2));

  paymentDetailsRef.current = {
    currency,
    price,
    priceWithCoupon,
    couponDiscount,
    subtotal,
    total,
    orderExpenses,
    coupon,
  };

  const details = [
    ...(cardInfo ? [[t.PaymentType, `${cardInfo.brand} ${cardInfo.type}`]] : []),
    ...(cardInfo ? [[t.CreditCardLast4, `${'xxxx-'.repeat(3)}${cardInfo.last4}`]] : []),
    ...(cardInfo ? [[t.CreditCardExp, `${cardInfo.expMonth}/${cardInfo.expYear}`]] : []),
    ...paxes.flatMap(({ pax, room, pkg }, i) => [
      [t.PropertyPrice + (paxes.length > 1 ? `, ${i + 1}` : ''), `${pkg.currency} $${money(pkg.finalPrice)}`],
    ]),
    ...(coupon?.applicable
      ? [[t.CouponDiscount, `-${coupon.discountType === AdjustmentType.Percentage ? '%' : `${currency} $`}${couponDiscount}`]]
      : []),
    [
      `<b>${t.Subtotal}</b>`,
      () => (
        <Typography variant="h6">
          {currency} ${money(subtotal)}
        </Typography>
      ),
      { liSx: { background: COLOR.GRAY_ITEM_OVER } },
    ],
    ...orderExpenses.map((expense) => [expense?.textPerLanguage?.[language] || expense.text, `${currency} ${expense.feePrice}`]),
    [
      `<b>${t.Total}</b>`,
      () => (
        <Typography variant="h6">
          {currency} ${money(total)}
        </Typography>
      ),
      { liSx: { background: COLOR.GRAY_ITEM_OVER } },
    ],
  ];

  return (
    <>
      <Box pt={2} sx={{ border: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`, ...sx }}>
        <Typography variant="h5" mb={2} ml={3}>
          {t.PaymentInformation}
        </Typography>

        <Box component="ul" sx={{ borderTop: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}` }}>
          {details.map(([key, value, props]) => (
            <Box
              key={key}
              component="li"
              sx={{ listStyle: 'none', p: 2, borderBottom: `1px solid ${COLOR.GRAY_LINE_SEPARATOR}`, ...props?.liSx }}
            >
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <Typography sx={{ flex: 2 }}>{HTMLReactParser(key)}</Typography>
                <Box sx={{ flex: 3, pl: 2 }}>
                  {isString(value) ? <Typography>{value}</Typography> : isFunction(value) ? value() : value}
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>

      <Box mt={2} mr={1}></Box>
      {couponEditable && (
        <Coupon
          couponEditable={couponEditable}
          coupon={coupon}
          price={price}
          clientName={clientName}
          agentName={agentName}
          onSetCoupon={setCoupon}
        />
      )}
    </>
  );
};

export default PaymentReservationDetails;
