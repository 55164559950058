import { Navigate, Outlet, Route } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';
import ErrorPage from '../ErrorPage';

import ProtectedRoute from '../auth/ProtectedRoute';
import { UserRole } from '../../types/auth';
import ProfilePage from './ProfilePage';
import AccountLayout from './AccountLayout';
import BookingsPage from './BookingsPage';
import FavoritesPage from './FavoritesPage';
import SettingsPage from './SettingsPage';

const accountRouter = (
  <Route
    path="/account"
    element={
      <ProtectedRoute requiredRoles={[UserRole.Customer, UserRole.Admin]}>
        <AccountLayout>
          <Outlet />
        </AccountLayout>
      </ProtectedRoute>
    }
    errorElement={<ErrorPage />}
  >
    <Route index element={<Navigate to="profile" />} errorElement={<ErrorPage />} />
    <Route path="profile" element={<ProfilePage />} errorElement={<ErrorPage />} />
    <Route path="favorites" element={<FavoritesPage />} errorElement={<ErrorPage />} />
    <Route path="bookings" element={<BookingsPage />} errorElement={<ErrorPage />} />
    <Route path="settings" element={<SettingsPage />} errorElement={<ErrorPage />} />

    <Route path="*" element={<NotFoundPage />} />
  </Route>
);

export default accountRouter;
