import AdminAPI from '../../api/admin';
import { Box, Card, CardContent, InputAdornment, MenuItem, Typography } from '@mui/material';
import { t } from '../../translations';
import { z } from 'zod';
import { DataGridColumn, getListComponent } from '../../components/CRUDAdmin/list';
import { UserWithInfo } from '../../types/user';
import Image from 'mui-image';
import { AdjustmentType } from '../../types';
import { getEditorComponent } from '../../components/CRUDAdmin/edit';

const columns: DataGridColumn<UserWithInfo>[] = [
  {
    field: 'fullName' as 'email',
    headerName: t.FullName,
    width: 120,
    renderCell: ({ row: user }) => {
      return <Box sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{user.userInfo.fullName}</Box>;
    },
  },
  {
    field: 'email',
    headerName: t.Email,
    width: 180,
  },
  {
    field: 'role',
    headerName: t.Role,
    width: 100,
  },
  {
    field: 'authProviders',
    headerName: t.AuthProviders,
    width: 180,
    renderCell: ({ row: user }) => {
      return (
        <ul>
          {user.authProviders.map((authProvider) => (
            <li key={authProvider}>{authProvider}</li>
          ))}
        </ul>
      );
    },
  },
  {
    field: 'ips',
    headerName: 'IPs',
    width: 100,
    renderCell: ({ row: user }) => {
      return (
        <ul>
          {user.ips.filter(Boolean).map((ip) => (
            <li key={ip}>{ip}</li>
          ))}
        </ul>
      );
    },
  },
  {
    field: 'emailAndPassDataProvider',
    headerName: t.Verified,
    width: 80,
    renderCell: ({ row: user }) => {
      return <Box>{user.emailAndPassDataProvider?.verified}</Box>;
    },
  },
  {
    field: 'image' as 'userInfo',
    headerName: t.Images,
    width: 180,
    renderCell: ({ row: user }) => {
      if (!user.userInfo.image) {
        return null;
      }

      return (
        <Box>
          <Image src={user.userInfo.image} width={100} height={100} />
        </Box>
      );
    },
  },
  {
    field: 'discountType' as 'userInfo',
    headerName: t.Discount,
    width: 120,
    renderCell: ({ row: user }) => (
      <Box>
        {!user.userInfo.discountAmount ? (
          t.NoDiscount
        ) : (
          <Typography>
            {user.userInfo.discountAmount} {user.userInfo.discountType === AdjustmentType.Percentage ? '%' : AdjustmentType.Fixed}
          </Typography>
        )}
      </Box>
    ),
  },
];

const UserSchema = z.object({
  userInfo: z.object({
    discountType: z.nativeEnum(AdjustmentType).optional(),
    discountAmount: z.coerce.number().optional(),
  }),
});

const UserForm = ({ data, textInput, selectInput }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 750, position: 'relative' }}>
      <CardContent>
        <Box mb={3}>
          {selectInput(
            'userInfo.discountType',
            [
              <MenuItem value={AdjustmentType.Percentage}>{t.Percentage}</MenuItem>,
              <MenuItem value={AdjustmentType.Fixed}>{t.Fixed}</MenuItem>,
            ],
            { label: t.DiscountType }
          )}
        </Box>
        <Box mb={3}>
          {textInput('userInfo.discountAmount', {
            label: t.DiscountAmount,
            InputProps: {
              startAdornment: data.discountType === AdjustmentType.Percentage ? <InputAdornment position="start">%</InputAdornment> : '',
            },
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

const baseProps = {
  name: t.Users,
  baseRoute: 'users',
  schema: UserSchema,
  FormComponent: UserForm,
};

export const ListUsers = getListComponent({
  ...baseProps,
  autoHeight: true,
  columns,
  editable: true,
  deletable: false,
  creatable: false,
  dataLoader: AdminAPI.users.getAll,
});

export const EditUsers = getEditorComponent({
  ...baseProps,
  dataLoader: (params) => AdminAPI.users.find(params.id),
  save: (props) => AdminAPI.users.update(props._id, props),
});
