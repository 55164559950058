import { CredentialResponse } from '@react-oauth/google';
import apiRequest from './api-request';
import { JWTToken, SigninResponse, TokenActionResult } from '../types/auth';

export const AuthAPI = {
  signin: async (props: { email: string, pass: string }): Promise<SigninResponse> =>
    (await apiRequest.post('/auth/signin', props)).data as SigninResponse,
  signup: async (props: { email: string, pass: string, fullName: string }) =>
    (await apiRequest.post('/auth/signup', props)).data,
  googleSignin: async (credentialResponse: CredentialResponse): Promise<SigninResponse> =>
    (await apiRequest.post('/auth/google-signin', {
      token: credentialResponse.credential
    })).data as SigninResponse,
  verifyEmailWithToken: async (jwtToken: JWTToken) =>
    (await apiRequest.post(`/auth/email-verification/${jwtToken}`)).data?.tokenActionResult as TokenActionResult,
  resendEmailVerification: async (email: string) =>
    (await apiRequest.post('/auth/email-verification', { email })).data,
  sendPassRecoveryVerification: async (email: string) =>
    (await apiRequest.post('/auth/pass-recovery', { email })).data,
  verifyPassRecovery: async (props: { pass: string, jwtToken: string }) =>
    (await apiRequest.post(`/auth/pass-recovery/${props.jwtToken}`, props)).data?.tokenActionResult as TokenActionResult,
}
