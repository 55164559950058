import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthAPI } from '../../api/auth-api';
import { CredentialResponse } from '@react-oauth/google';
import { CurrentUser, JWTToken, SigninResponse, TokenActionResult } from '../../types/auth';
import { getObjFromLocalStorage, saveObjToLocalStorage } from '../../helpers/utils';
import { UserAPI } from '../../api/user-api';
import { onSetAxiosInterceptor } from './AxiosInterceptor';

const AUTH_KEY = 'auth_v1';

type BaseLoginProps = {
  redirectTo?: string;
};

export type SigninProps = BaseLoginProps & {
  email: string;
  pass: string;
};

export type SignupProps = BaseLoginProps & {
  email: string;
  pass: string;
  fullName: string;
};

export type GoogleSigninProps = BaseLoginProps & {
  credentialResponse: CredentialResponse;
};

type AuthContextType = {
  isLoggedIn: boolean;
  authToken?: string;
  currentUser?: CurrentUser;
  signin: (props: SigninProps) => Promise<void>;
  signup: (props: SignupProps) => Promise<void>;
  googleSignin: (props: GoogleSigninProps) => Promise<void>;
  logout: () => Promise<void>;
  reloadCurrenUser: () => Promise<void>;
  verifyEmailWithToken: (jwtToken: JWTToken) => Promise<TokenActionResult>;
  resendEmailVerification: (email: string) => Promise<void>;
  sendPassRecoveryVerification: (email: string) => Promise<void>;
  verifyPassRecovery: (props: { pass: string; jwtToken: string }) => Promise<TokenActionResult>;
  setCurrentUser: (cb: (currentUser: CurrentUser) => CurrentUser) => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const navigateTo = useRef<any>(null);

  const reloadCurrenUser = () => {
    return UserAPI.currentUser().then((newCurrentUser) => {
      setAuth((currentAuth) => {
        if (!currentAuth.isLoggedIn) {
          return { ...currentAuth };
        }

        return {
          ...currentAuth,
          currentUser: newCurrentUser,
        };
      });
    });
  };

  const [auth, setAuth] = useState<{
    isLoggedIn: boolean;
    authToken?: string;
    currentUser?: CurrentUser;
  }>(() => {
    const objStored = getObjFromLocalStorage<SigninResponse>(AUTH_KEY);

    if (objStored.currentUser) {
      onSetAxiosInterceptor(reloadCurrenUser);
    }

    return {
      isLoggedIn: !!objStored.token,
      authToken: objStored.token,
      currentUser: objStored.currentUser,
    };
  });

  const onSignin = ({ signinResponse, redirectTo }: { signinResponse: SigninResponse; redirectTo?: string }) => {
    saveObjToLocalStorage(AUTH_KEY, signinResponse);
    navigateTo.current = redirectTo;

    setAuth({
      isLoggedIn: true,
      authToken: signinResponse.token,
      currentUser: signinResponse.currentUser,
    });
  };

  const signin = async (props: SigninProps) => {
    const signinResponse = await AuthAPI.signin({
      email: props.email,
      pass: props.pass,
    });

    onSignin({
      signinResponse,
      redirectTo: props.redirectTo,
    });
  };

  const signup = async (props: SignupProps) => {
    await AuthAPI.signup({
      email: props.email,
      pass: props.pass,
      fullName: props.fullName,
    });
  };

  const googleSignin = async (props: GoogleSigninProps) => {
    const signinResponse = await AuthAPI.googleSignin(props.credentialResponse);

    onSignin({
      signinResponse,
      redirectTo: props.redirectTo,
    });
  };

  const verifyEmailWithToken = AuthAPI.verifyEmailWithToken;
  const resendEmailVerification = AuthAPI.resendEmailVerification;

  const sendPassRecoveryVerification = AuthAPI.sendPassRecoveryVerification;
  const verifyPassRecovery = AuthAPI.verifyPassRecovery;

  const logout = async () => {
    localStorage.removeItem(AUTH_KEY);
    navigateTo.current = '/';

    setAuth({
      isLoggedIn: false,
      authToken: undefined,
      currentUser: undefined,
    });
  };

  const setCurrentUser = (cb) => {
    setAuth((currentAuth) => ({
      ...currentAuth,
      currentUser: cb(currentAuth.currentUser),
    }));
  };

  const contextValue: AuthContextType = {
    isLoggedIn: auth.isLoggedIn,
    currentUser: auth.currentUser,
    authToken: auth.authToken,
    signin,
    signup,
    googleSignin,
    logout,
    reloadCurrenUser,
    verifyEmailWithToken,
    resendEmailVerification,
    sendPassRecoveryVerification,
    verifyPassRecovery,
    setCurrentUser,
  };

  if (navigateTo.current) {
    const url = navigateTo.current;
    navigateTo.current = null;
    setTimeout(() => navigate(url), 1);
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
