export type JWTToken = string;

export enum TokenActionResult {
  Success = 'Success',
  AlreadyVerified = 'AlreadyVerified',
  TokenExpired = 'TokenExpired',
  TokenInvalid = 'TokenInvalid',
}

export enum UnauthorizedReason {
  PendingEmailVerification = 'PendingEmailVerification',
}

export enum AuthProvider {
  EmailAndPass = 'EmailAndPass',
  Google = 'Google',
}

export enum UserRole {
  Customer = 'Customer',
  Admin = 'Admin',
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Unspecified = 'Unspecified',
  Undisclosed = 'Undisclosed',
}

export type SigninResponse = {
  token: string;
  currentUser: CurrentUser;
}

export type CurrentUser = {
  email: string;
  role: UserRole;
  fullName?: string;
  image?: string;
  gender?: Gender;
  dateOfBirth?: Date;
  phoneNumber?: string;
  bio?: string;
  favoriteProperties?: string[];
  bookings?: string[];
}
