import { useState } from 'react';
import { FormControlLabel, IconButton, InputAdornment, MenuItem, Switch, TextField, TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import { t } from '../translations';
import { getSchemaErrors } from '../helpers/validations';
import { useWithDataLoader } from './use-data-loader';
import { get, isString, set } from 'lodash';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ImagePicker } from '../components/Inputs/ImagePicker';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type UseFormProps<TData = any, TResult = any> = {
  schema: Parameters<typeof getSchemaErrors>[1];
  dataLoader: Parameters<typeof useWithDataLoader>[0];
  showToastErrorOnSaveFail?: boolean;
  defaultSaveErrorMessage?: string;
  save: (record: TData) => Promise<any>;
  onSaveSuccess?: (result: TResult) => Promise<void>;
  onSaveFail?: (err: Error) => Promise<void>;
  parseSaveErrorMessage?: (err: Error) => string;
};

export const useForm = function <TData = any, TResult = any>({
  schema,
  dataLoader,
  showToastErrorOnSaveFail,
  defaultSaveErrorMessage,
  save,
  onSaveSuccess,
  onSaveFail,
  parseSaveErrorMessage,
}: UseFormProps<TData, TResult>) {
  const { data, setData, isOk, message: loadMessage } = useWithDataLoader(dataLoader);
  const [showErrors, setShowErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  const editable = Boolean(save);
  const [hasError, errors] = data ? getSchemaErrors(data, schema) : [false, {}];
  const [errorMessage, setErrorMessage] = useState('');

  const [showPasswords, setShowPasswords] = useState<Record<string, boolean>>({});

  const onChange = (arg: Parameters<typeof setData>[0]) => setData(arg);

  const onInputChange =
    (propName, targetProp: 'value' | 'checked' = 'value') =>
    (ev) => {
      setData((currentData) => {
        const result = { ...currentData };
        set(result, propName, ev.target[targetProp]);
        return result;
      });

      setErrorMessage('');
    };

  const onToggleShowPassword = (propPath: string) => () => {
    setShowPasswords((current) => ({
      ...current,
      [propPath]: !current[propPath],
    }));
  };

  const saveForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    saveData();
  };

  const saveData = async () => {
    setShowErrors(true);

    if (hasError) {
      if (showToastErrorOnSaveFail) {
        toast.error('Error');
      }

      return;
    }

    setSaving(true);

    try {
      const result = await save(data);
      await onSaveSuccess?.(result);
    } catch (err: any) {
      const newErrorMessage = parseSaveErrorMessage ? parseSaveErrorMessage(err) : err?.response?.data?.message || defaultSaveErrorMessage;
      setErrorMessage(newErrorMessage);

      if (showToastErrorOnSaveFail) {
        toast.error(errorMessage);
      }
      await onSaveFail?.(err);
    }

    setSaving(false);
  };

  const textInput = (propPath: string, textFieldProps: TextFieldProps): JSX.Element => (
    <TextField
      fullWidth
      label={`${t.Text}*`}
      value={get(data, propPath) || ''}
      error={!!(showErrors && get(errors, propPath))}
      onChange={onInputChange(propPath)}
      helperText={showErrors && get(errors, propPath)}
      {...textFieldProps}
      InputProps={{
        readOnly: !editable,
        ...textFieldProps?.InputProps,
      }}
    />
  );
  const passwordInput = (propPath: string, textFieldProps: TextFieldProps): JSX.Element => (
    <TextField
      fullWidth
      label={`${t.Text}*`}
      value={get(data, propPath) || ''}
      error={!!(showErrors && get(errors, propPath))}
      onChange={onInputChange(propPath)}
      helperText={showErrors && get(errors, propPath)}
      type={showPasswords[propPath] ? 'text' : 'password'}
      {...textFieldProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onToggleShowPassword(propPath)}
              onMouseDown={(ev) => ev.preventDefault()}
              edge="end"
            >
              {showPasswords[propPath] ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        readOnly: !editable,
        ...textFieldProps?.InputProps,
      }}
    />
  );
  const booleanInput = (prop, opts) => (
    <FormControlLabel {...opts} control={<Switch checked={get(data, prop)} onChange={onInputChange(prop, 'checked')} />} />
  );
  const selectInput = (prop, menuItems, opts) => (
    <TextField
      select
      fullWidth
      value={get(data, prop) || ''}
      error={!!(showErrors && get(errors, prop))}
      helperText={showErrors && get(errors, prop)}
      onChange={onInputChange(prop)}
      {...opts}
      InputProps={{
        readOnly: !editable,
        ...opts?.InputProps,
      }}
    >
      {menuItems.map((value) =>
        isString(value) ? (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ) : (
          value
        )
      )}
    </TextField>
  );
  const dateTimeInput = (prop, opts) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        sx={{ minWidth: 300 }}
        value={get(data, prop)}
        onChange={(value) => onInputChange(prop)({ target: { value } })}
        slotProps={{
          textField: {
            error: !!(showErrors && get(errors, prop)),
            helperText: showErrors && get(errors, prop),
          },
        }}
      />
    </LocalizationProvider>
  );
  const imageInput = (prop) => (
    <ImagePicker
      image={get(data, prop)}
      editable={editable}
      error={!!(showErrors && get(errors, prop))}
      helperText={showErrors && get(errors, prop)}
      onChangeFile={(file) => onInputChange(prop)({ target: { value: file } })}
    />
  );

  return {
    isOk,
    loadMessage,
    saving,
    data,
    errors,
    showErrors,
    editable,
    hasError,
    errorMessage,
    setData,
    onChange,
    onInputChange,
    //
    textInput,
    passwordInput,
    booleanInput,
    selectInput,
    dateTimeInput,
    imageInput,
    //
    saveData,
    saveForm,
  };
};
